<template>
  <a v-if="href" :href="href" :disabled="disabled || loading" :class="classes" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
    <icon v-if="typeof icon === 'string'" :name="icon" :small="small" />
  </a>
  <button v-else :disabled="disabled || loading" :type="type" :class="classes" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
    <icon v-if="typeof icon === 'string'" :name="icon" :small="small" />
    <mdl-progressbar v-if="loading" indeterminate />
  </button>
</template>

<script>
export default {
  props: {
    href: { type: String },
    disabled: { type: Boolean, default: false },
    raised: { type: Boolean, default: false },
    colored: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    opaque: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    warning: { type: Boolean, default: false },
    info: { type: Boolean, default: false },
    accent: { type: Boolean, default: false },
    ripple: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    fab: { type: Boolean, default: false },
    miniFab: { type: Boolean, default: false },
    icon: { type: [Boolean, String], default: false },
    type: { type: String, default: 'button' },
    loading: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
  },

  computed: {
    link() {
      if (this.disabled) {
        return 'javascript:void(0);'
      }

      return this.href
    },
    classes: function () {
      return {
        'mdl-button': true,
        'mdl-js-button': true,
        'mdl-button--raised': this.raised,
        'mdl-button--outlined': this.outlined || this.opaque,
        'mdl-button--opaque': this.opaque,
        'mdl-button--colored': this.colored,
        'mdl-button--primary': this.primary,
        'mdl-button--success': this.success,
        'mdl-button--danger': this.danger,
        'mdl-button--warning': this.warning,
        'mdl-button--info': this.info,
        'mdl-button--accent': this.accent,
        'mdl-button--fab': this.fab || this.miniFab,
        'mdl-button--mini-fab': this.miniFab,
        'mdl-button--icon': this.icon,
        'mdl-js-ripple-effect': false,
        'mdl-button--inverted': this.inverted,
        'pointer-events-none': this.disabled,
      }
    },
  },
}
</script>
