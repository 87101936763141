class ShopCart {
  path

  constructor(path) {
    this.path = path
  }

  get basePath() {
    return `${this.path}/cart`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  clear() {
    return Munio.$http.delete(this.basePath)
  }

  add({ productId, quantity = 1, unlimited = false, sessions = [] }) {
    return Munio.$http.post(`${this.basePath}/${productId}`, { quantity, unlimited, sessions })
  }

  set({ productId, sessionId, quantity = 1, unlimited = false }) {
    let endpoint = sessionId ? `${productId}/${sessionId}` : productId

    return Munio.$http.put(`${this.basePath}/${endpoint}`, { quantity, unlimited })
  }
}

export default ShopCart
