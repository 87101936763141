import ShopCart from './ShopCart.js'
import ShopProduct from './ShopProduct.js'
import ShopCheckout from './ShopCheckout'
import ShopCustomer from './ShopCustomer.js'

class Shop {
  slug

  constructor(slug) {
    this.slug = slug
  }

  get basePath() {
    return `/api/shop/${this.slug}`
  }

  salespeople() {
    return Munio.$http.get(this.basePath + '/salespeople')
  }

  products() {
    return Munio.$http.get(this.basePath + '/product')
  }

  product(id) {
    return new ShopProduct(this.basePath, id)
  }

  customer(id) {
    return new ShopCustomer(this.basePath, id)
  }

  get cart() {
    return new ShopCart(this.basePath)
  }

  get checkout() {
    return new ShopCheckout(this.slug)
  }
}

export default Shop
