import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import $ from 'jquery'

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
export type RequestData = URLSearchParams | Record<string, any>
export type Response = AxiosResponse & {
  data: any
  headers?: Record<string, string>
}
export type CancellablePromise<T> = Promise<T> & {
  cancel(reason?: any): void
}

export class Http {
  get<T>(
    url: string,
    data?: RequestData,
    headers?: AxiosRequestHeaders,
    config?: {},
  ): CancellablePromise<AxiosResponse<T>> {
    return request('GET', url, data, headers, config)
  }

  post<T>(
    url: string,
    data?: RequestData,
    headers?: AxiosRequestHeaders,
    config?: {},
  ): CancellablePromise<AxiosResponse<T>> {
    return request('POST', url, data, headers, config)
  }

  put<T>(
    url: string,
    data?: RequestData,
    headers?: AxiosRequestHeaders,
    config?: {},
  ): CancellablePromise<AxiosResponse<T>> {
    return request('PUT', url, data, headers, config)
  }

  patch<T>(
    url: string,
    data?: RequestData,
    headers?: AxiosRequestHeaders,
    config?: {},
  ): CancellablePromise<AxiosResponse<T>> {
    return request('PATCH', url, data, headers, config)
  }

  delete<T>(
    url: string,
    data?: RequestData,
    headers?: AxiosRequestHeaders,
    config?: {},
  ): CancellablePromise<AxiosResponse<T>> {
    return request('DELETE', url, data, headers, config)
  }
}

function request<T>(
  method: RequestMethod,
  url: string,
  data?: RequestData,
  headers?: AxiosRequestHeaders,
  config?: {},
): CancellablePromise<AxiosResponse<T>> {
  const meth = method.toLowerCase()
  const controller = new AbortController()
  const options = Object.assign(
    {},
    {
      method: meth,
      url,
      data,
      headers,
      params: meth === 'get' ? data : {},
      signal: controller.signal,
    },
    config,
  )

  let req = axios.request(options) as CancellablePromise<AxiosResponse<T>>
  req.cancel = (reason: string) => controller.abort(reason)
  return req
}

function handleResponse(response: Response) {
  Munio.handleJSONResponseData(response.data)

  if (response.headers?.hasOwnProperty('x-counters')) {
    Munio.Counters.reload(response.headers['x-counters'])
  }
}

// Setup Axios
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.interceptors.request.use(function (config) {
  config.headers['X-CSRF-TOKEN'] = window.csrf_token()
  config.headers['X-XSRF-TOKEN'] = window.xsrf_token()
  config.headers['X-Language'] = Munio.config.i18n.key
  return config
})

axios.interceptors.response.use(
  function (response) {
    handleResponse(response)

    return response
  },
  function (error) {
    if (error.response) {
      handleResponse(error.response)
    }

    return Promise.reject(error)
  },
)

// Setup jQuery
$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': window.csrf_token(),
    'X-XSRF-TOKEN': window.xsrf_token(),
    'X-Language': window.Munio.config.i18n.key,
  },
})

$(document).ajaxSuccess(function (event, jqxhr, options, data) {
  window.Munio.handleJSONResponseData(data)

  const countersUlid = jqxhr.getResponseHeader('x-counters')

  if (countersUlid) {
    window.Munio.Counters.reload(countersUlid)
  }
})

window.Munio.$http = new Http()
