import Backbone from '@/libs/backbone.js'
import { bindAll } from 'lodash'

Munio.behaviors.categoryModal = {
  attach: function (context, config) {
    const App = Backbone.View.extend({
      global: null,
      shops: null,

      initialize: function (options) {
        bindAll(this, 'change', 'checked', 'unchecked')
        if (!this.$el.length) return

        this.global = this.$('input[name="global"]')
        this.shops = this.$('input[name^="shop"]')

        this.global.on('change', this.change)
      },

      change: function () {
        if (this.global.is(':checked')) {
          this.checked()
        } else {
          this.unchecked()
        }
      },

      checked: function () {
        this.shops.attr('disabled', true)
      },

      unchecked: function () {
        this.shops.attr('disabled', false)
      },
    })

    this.instance = new App({ el: $('#category-modal', context) })
  },
}
