<template>
  <div
    class="mdl-data-table-container"
    :class="{
      'mdl-data-table--loading': loading,
      'mdl-data-table--selectable': selectable,
      'mdl-data-table--scroll-left': hasScrollLeft,
      'mdl-data-table--scroll-right': hasScrollRight,
    }"
  >
    <div ref="tableWrap" class="mdl-data-table-wrapper">
      <table ref="table" class="mdl-data-table mdl-js-data-table is-upgraded" data-upgraded="MaterialDataTable">
        <caption v-if="showCaption" :class="[{ 'is-active': numSelected > 0 }]">
          <div class="mdl-data-table__caption">
            <template v-if="numSelected">
              <div class="mdl-data-table__caption-text">
                {{ trans_choice(selectionLabel, numSelected, { num: numSelected }) }}
              </div>
              <div class="mdl-data-table__caption-actions">
                <slot name="selection"></slot>
              </div>
            </template>
            <template v-else>
              <h3 v-if="title" class="mdl-data-table__caption-title">{{ title }}</h3>
              <div v-else-if="$slots.title" class="mdl-data-table__caption-title">
                <slot name="title"></slot>
              </div>
              <div class="mdl-data-table__caption-toolbar">
                <slot name="toolbar"></slot>
              </div>
            </template>
          </div>
        </caption>

        <slot></slot>
      </table>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import useScrollPan from '@/munio/vue/composables/useScrollPan'
import useTableEnhancement from '@/munio/vue/composables/useTableEnhancement'

export default {
  setup() {
    const table = ref()
    const tableWrap = ref()
    const thead = computed(() => table.value?.querySelector('thead'))

    const { isStuck, hasScrollLeft, hasScrollRight } = useTableEnhancement(tableWrap, thead)

    useScrollPan(
      tableWrap,
      computed(() => {
        return hasScrollLeft.value || hasScrollRight.value
      }),
    )

    return { table, tableWrap, isStuck, hasScrollLeft, hasScrollRight }
  },

  props: {
    loading: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    selectionLabel: { type: String, default: ':num selected' },
    title: { type: String, default: null },
    value: { type: Array, default: () => [] },
  },

  provide() {
    return {
      dataTable: this,
    }
  },

  data() {
    return {
      rows: [],
      rowsSelected: this.value,
    }
  },

  watch: {
    value(selected) {
      this.rowsSelected = selected
      this.$forceUpdate()
    },

    rowsSelected(value) {
      this.$emit('selection', value)
    },
  },

  computed: {
    rowsSelectable() {
      return this.rows.filter((row) => row.selectable)
    },

    numRows() {
      return this.rows.length
    },

    numSelectable() {
      return this.rowsSelectable.length
    },

    numSelected() {
      return this.rowsSelected.length
    },

    showCaption() {
      return (
        this.numSelected ||
        ![null, undefined].includes(this.title) ||
        !!this.$slots.title ||
        !!this.$slots.selection ||
        !!this.$slots.toolbar
      )
    },
  },

  methods: {
    addRow(row) {
      this.rows.push(row)
      this.$emit('rowCount', this.numRows, this.numSelectable)
    },

    removeRow(row) {
      const index = this.rows.indexOf(row)

      if (index >= 0) {
        this.rows = [...this.rows.slice(0, index), ...this.rows.slice(index + 1)]
        this.rowsSelected = this.rowsSelected.filter((v) => v !== row.value)
        this.$emit('rowCount', this.numRows, this.numSelectable)
      }
    },

    isRowSelected(value) {
      return this.rowsSelected.indexOf(value) !== -1
    },

    toggleRow(checked, value) {
      let selected = [...this.rowsSelected]

      if (value) {
        const index = selected.indexOf(value)

        if (checked) {
          if (index === -1) {
            selected.push(value)
          }
        } else {
          if (index >= 0) {
            selected = [...selected.slice(0, index), ...selected.slice(index + 1)]
          }
        }
      }

      this.rowsSelected = selected
      this.$emit('toggleRow', checked, value)
      this.$emit('input', this.rowsSelected)
    },

    toggleAll(checked) {
      if (checked) {
        this.rowsSelected = this.rowsSelectable.map((row) => row.value)
      } else {
        this.rowsSelected = []
      }

      this.$emit('toggleAll', checked)
      this.$emit('input', this.rowsSelected)
    },

    getCount() {
      return {
        rows: this.numRows,
        selectable: this.numSelectable,
        selected: this.numSelected,
      }
    },
  },
}
</script>
