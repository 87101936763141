import dateIsValid from 'date-fns/isValid'
import dateParse from 'date-fns/parse'
import dateParseISO from 'date-fns/parseISO'
import dateParseJSON from 'date-fns/parseJSON'
import dateFormat from 'date-fns/format'
import dateDifferenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import dateDifferenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import dateDifferenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import dateDifferenceInYears from 'date-fns/differenceInYears'
import dateAddDays from 'date-fns/addDays'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/default.js'
import 'flatpickr/dist/l10n/cs.js'
import 'flatpickr/dist/l10n/da.js'
import 'flatpickr/dist/l10n/de.js'
import 'flatpickr/dist/l10n/et.js'
import 'flatpickr/dist/l10n/es.js'
import 'flatpickr/dist/l10n/fi.js'
import 'flatpickr/dist/l10n/fr.js'
import 'flatpickr/dist/l10n/is.js'
import 'flatpickr/dist/l10n/it.js'
import 'flatpickr/dist/l10n/lv.js'
import 'flatpickr/dist/l10n/lt.js'
import 'flatpickr/dist/l10n/hu.js'
import 'flatpickr/dist/l10n/no.js'
import 'flatpickr/dist/l10n/pl.js'
import 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/l10n/ro.js'
import 'flatpickr/dist/l10n/sv.js'
import 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/l10n/uk.js'
import localeCs from 'date-fns/locale/cs/index.js'
import localeDa from 'date-fns/locale/da/index.js'
import localeDe from 'date-fns/locale/de/index.js'
import localeEt from 'date-fns/locale/et/index.js'
import localeEn from 'date-fns/locale/en-GB/index.js'
import localeEs from 'date-fns/locale/es/index.js'
import localeFi from 'date-fns/locale/fi/index.js'
import localeFr from 'date-fns/locale/fr/index.js'
import localeFrCa from 'date-fns/locale/fr-CA/index.js'
import localeIs from 'date-fns/locale/is/index.js'
import localeIt from 'date-fns/locale/it/index.js'
import localeLv from 'date-fns/locale/lv/index.js'
import localeLt from 'date-fns/locale/lt/index.js'
import localeHu from 'date-fns/locale/hu/index.js'
import localeNo from 'date-fns/locale/nb/index.js'
import localePl from 'date-fns/locale/pl/index.js'
import localeRu from 'date-fns/locale/ru/index.js'
import localeRo from 'date-fns/locale/ro/index.js'
import localeSv from 'date-fns/locale/sv/index.js'
import localeTr from 'date-fns/locale/tr/index.js'
import localeUk from 'date-fns/locale/uk/index.js'

const dateFnsLocales = {
  cs: localeCs,
  da: localeDa,
  de: localeDe,
  en: localeEn,
  es: localeEs,
  es_US: localeEs,
  et: localeEt,
  fi: localeFi,
  fr: localeFr,
  fr_CA: localeFrCa,
  hu: localeHu,
  is: localeIs,
  it: localeIt,
  lt: localeLt,
  lv: localeLv,
  no: localeNo,
  pl: localePl,
  ro: localeRo,
  ru: localeRu,
  sv: localeSv,
  tr: localeTr,
  uk: localeUk,
}

const options = {
  locale: dateFnsLocales.en,
  weekStartsOn: 1,
}

/**
 * @param Object{key: string, language: string, locale: string} language
 */
export function setLocale(language) {
  let dateFnsLocale = dateFnsLocales.en
  let flatpickrLocale = flatpickr.l10ns.default

  const candiates = [language.key, language.language, language.locale]

  candiates.forEach((locale) => {
    if (dateFnsLocales[locale]) {
      dateFnsLocale = dateFnsLocales[locale]
    }

    if (flatpickr.l10ns[locale]) {
      flatpickrLocale = flatpickr.l10ns[locale]
    }
  })

  // dateFns
  options.locale = dateFnsLocale

  // flatpickr
  flatpickr.localize(Object.assign({}, flatpickrLocale))
}

export function parseDate(value, format = null) {
  if (format !== null) {
    return dateParse(value, format)
  }

  let date = dateParseISO(value)

  if (!dateIsValid(date)) {
    date = dateParseJSON(value)
  }

  return date
}

export function parseTime(time, date = new Date()) {
  if (!time) return null

  const { groups } = /(?<hour>2[0-3]|[0-1]?[0-9])[^0-9]?(?<minute>[0-5]?[0-9]?)/.exec(time)
  if (!groups) return null
  let { hour, minute } = groups
  if (!hour) return null

  if (String(hour).length === 1) hour = '0' + hour
  if (!minute) minute = '00'
  if (String(minute).length === 1) minute = minute + '0'

  const dateTime = `${toDateString(date)}T${hour}:${minute}`

  return parseDate(dateTime)
}

export function daysDifference(from, to = new Date()) {
  return Math.abs(dateDifferenceInCalendarDays(parseDate(from), parseDate(to)))
}

export function daysBetween(from, to = new Date()) {
  return daysDifference(from, to) - 1
}

export function daysInRange(from, to = new Date()) {
  return daysDifference(from, to) + 1
}

export function addDays(date, days) {
  return dateAddDays(parseDate(date), days)
}

export function addDay(date) {
  return addDays(date, 1)
}

export function weeksInRange(from, to = new Date()) {
  return Math.abs(dateDifferenceInCalendarWeeks(parseDate(from), parseDate(to))) + 1
}

export function yearsDifference(from, to = new Date()) {
  return Math.abs(dateDifferenceInCalendarYears(parseDate(from), parseDate(to)))
}

export function age(date, when = new Date()) {
  return Math.abs(dateDifferenceInYears(parseDate(date), parseDate(when)))
}

export function format(date, format) {
  if (!date) {
    return null
  }
  return dateFormat(parseDate(date), format, options)
}

export function toDateTimeString(date) {
  if (!date) {
    return null
  }
  return dateFormat(parseDate(date), 'yyyy-MM-dd HH:mm:ss')
}

export function toDateString(date) {
  if (!date) {
    return null
  }
  return dateFormat(parseDate(date), 'yyyy-MM-dd')
}

export function toTimeString(date, seconds = true) {
  if (!date) {
    return null
  }
  return dateFormat(parseDate(date), 'HH:mm' + (seconds ? ':ss' : ''))
}

export function isValid(value) {
  if (!value) {
    return false
  }
  return dateIsValid(parseDate(value))
}

Munio.Date = {
  parse: parseDate,
  daysDifference,
  daysBetween,
  daysInRange,
  addDays,
  addDay,
  weeksInRange,
  yearsDifference,
  age,
  format,
  toDateTimeString,
  toDateString,
  toTimeString,
  isValid,
}
