<template>
  <div :class="['toolbar', { 'is-hidden': hide }]">
    <div v-if="title" class="toolbar__title">
      <h4>{{ title }}</h4>
    </div>
    <div v-else-if="$slots.title" class="toolbar__title">
      <slot name="title" />
    </div>

    <mdl-filters
      v-if="filters"
      ref="filters"
      :loading="loading"
      :inline="inline"
      :options="options"
      :center="center"
      v-on="$listeners"
    />

    <div class="toolbar__actions" v-if="$slots.actions || $slots.default || showCSV">
      <slot name="actions" />
      <mdl-csv
        v-if="showCSV"
        ref="csv"
        :loading="exporting"
        :disabled="loading || exporting"
        :csv="options.csv"
        @export="onExport"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import Filters from './Filters.vue'
import CSV from './CSV.vue'

export { Filters, CSV }

export default {
  inheritAttrs: false,

  name: 'Toolbar',

  components: {
    'mdl-filters': Filters,
    'mdl-csv': CSV,
  },

  props: {
    filters: [Boolean, Object],
    center: Boolean,
    exportUrl: String,
    inline: Boolean,
    hide: Boolean,
    loading: Boolean,
    title: String,
  },

  emits: ['change', 'submit'],

  model: {
    prop: 'filters',
    event: 'change',
  },

  data() {
    return {
      exporting: false,
    }
  },

  computed: {
    options() {
      if (typeof this.filters === 'object') {
        return this.filters
      }

      return this.filters === true && !this.inline ? Munio.state.filters : null
    },

    showCSV() {
      return this.exportUrl || this.options?.csv.length > 0
    },
  },

  methods: {
    filter(name, value) {
      if (this.$refs.filters) {
        return this.$refs.filters.filter(name, value)
      }
    },
    async onExport(options) {
      try {
        const url =
          this.exportUrl || window.location.protocol + '//' + window.location.hostname + window.location.pathname
        const params = Munio.urlParamsString(this.$refs.filters?.queryString)
        this.exporting = true
        await Munio.downloadCSV(url, options, params)
      } catch (err) {
        console.error(err)
      } finally {
        this.exporting = false
      }
    },
  },
}
</script>
