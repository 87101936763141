class AttemptNode {
  attemptId = null
  nodeId = null

  constructor(attemptId, nodeId) {
    this.attemptId = attemptId
    this.nodeId = nodeId
  }

  get basePath() {
    return `/api/attempt/${this.attemptId}/nodes/${this.nodeId}`
  }

  get linkPath() {
    return `${this.basePath}/link`
  }

  get() {
    return Munio.$http.get(this.basePath)
  }

  startCourse() {
    return Munio.$http.post(`${this.basePath}/course/start`)
  }

  approve(date) {
    let data = {}

    if (date) {
      data.completed_at = date
    }

    return Munio.$http.post(`${this.basePath}/approve`, data)
  }

  videoProgress(metadata) {
    return Munio.$http.post(`${this.basePath}/video/progress`, { metadata })
  }

  sessionEnroll(id) {
    return Munio.$http.post(`${this.basePath}/session/${id}`)
  }

  sessionUnenroll(id) {
    return Munio.$http.delete(`${this.basePath}/session/${id}`)
  }

  scorm(itemId) {
    return new Scorm(this.basePath, itemId)
  }
}

class Scorm {
  constructor(parentPath, itemId) {
    this.parentPath = parentPath
    this.itemId = itemId
  }

  get basePath() {
    return `${this.parentPath}/scorm/${this.itemId}`
  }

  init() {
    return Munio.$http.get(this.basePath)
  }

  commit(data, finish = false) {
    return Munio.$http.post(this.basePath, { data, finish })
  }
}

export default AttemptNode
