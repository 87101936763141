<template>
  <div class="product-price">
    <small class="text-info">
      <template v-if="membership">
        {{ trans('Member price') }}
      </template>
      <template v-else-if="!price">
        {{ trans('Members only') }}
      </template>
      <template v-else-if="membershipPrice"> {{ membershipPrice }} ({{ trans('Member price') }}) </template>
    </small>
    <div class="h5 mt-0 mb-0">{{ displayPrice }}</div>
  </div>
</template>

<script>
export default {
  props: {
    price: { type: Object },
    memberPrice: { type: Object },
    membership: { type: Boolean },
  },

  computed: {
    membershipPrice() {
      if (this.memberPrice && this.price) {
        if (this.memberPrice.value === this.price.value) {
          return null
        }
      }

      return this.memberPrice ? this.memberPrice.display : null
    },
    membershipDisplayPrice() {
      return this.memberPrice ? this.memberPrice.display : null
    },
    displayPrice() {
      if (this.price && !this.price.value) {
        return trans('For free')
      }

      const defaultPrice = this.price ? this.price.display : this.memberPrice.display

      if (this.membership) {
        return this.memberPrice ? this.memberPrice.display : defaultPrice
      }

      return defaultPrice
    },
  },
}
</script>
