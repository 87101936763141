<template>
  <div>
    <mdl-progressbar :indeterminate="true" v-if="fetching || purchasing || employing" />
    <template v-if="!fetching">
      <form @submit.prevent="employ" class="stacked" v-if="!hasCompany">
        <div class="p-8">
          <div class="alert alert-info">
            <p>{{ trans('Select employer') }}</p>
          </div>
          <fieldset>
            <company-search v-if="!checkoutData.company" v-model="company" />
          </fieldset>
          <div class="flex courses-center justify-end p-4" style="border-top: 1px solid rgba(0, 0, 0, 0.12)">
            <mdl-button type="button" @click="$emit('cancel')">{{ trans('Cancel') }}</mdl-button>
            <mdl-button type="submit" :disabled="!company || employing" primary raised>{{ trans('Next') }}</mdl-button>
          </div>
        </div>
      </form>
      <form @submit.prevent="purchase" class="stacked" v-else>
        <div class="p-8">
          <fieldset>
            <div class="form-group">
              <div class="form-label">
                <label>{{ trans('Recipient') }}</label>
              </div>
              <div class="form-value">
                <input type="text" readonly :value="checkoutData.company.name" />
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': !isValid('reference') }">
              <div class="form-label">
                <label for="reference">{{ trans('Invoice reference / Purchase order (PO)') }}</label>
              </div>
              <div class="form-value">
                <input
                  autofocus
                  type="text"
                  id="reference"
                  v-model="data.reference"
                  @keypress="setValid('reference')"
                />
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': !isValid('email') }">
              <div class="form-label">
                <label for="email">{{ trans('Invoice by e-mail') }}</label>
              </div>
              <div class="form-value">
                <input type="email" id="email" v-model="data.email" @keypress="setValid('email')" />
              </div>
            </div>
            <div class="form-group">
              <div class="form-label">
                <label for="address">{{ trans('Billing address') }}</label>
              </div>
              <div class="form-value">
                <m-select searchable v-model="data.address.id" id="address" name="address" :options="addresses" />
              </div>
            </div>
            <template v-if="isCustomAddress">
              <div class="form-group" :class="{ 'has-error': !isValid('address.line1') }">
                <div class="form-label">
                  <label for="address-line-1">{{ trans('Address') }}</label>
                </div>
                <div class="form-value">
                  <input
                    type="text"
                    id="address-line-1"
                    v-model="data.address.line1"
                    @keypress="setValid('address.line1')"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-value">
                  <input type="text" id="address-line-2" v-model="data.address.line2" />
                </div>
              </div>
              <div class="row">
                <div class="col-xs-3">
                  <div class="form-group" :class="{ 'has-error': !isValid('address.postcode') }">
                    <div class="form-label">
                      <label for="postcode">{{ trans('Post code') }}</label>
                    </div>
                    <div class="form-value">
                      <input
                        type="number"
                        id="postcode"
                        v-model="data.address.postcode"
                        @keypress="setValid('address.postcode')"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-9">
                  <div class="form-group" :class="{ 'has-error': !isValid('address.city') }">
                    <div class="form-label">
                      <label for="city">{{ trans('City') }}</label>
                    </div>
                    <div class="form-value">
                      <input type="text" id="city" v-model="data.address.city" @keypress="setValid('address.city')" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': !isValid('address.country') }">
                <div class="form-label">
                  <label for="country">{{ trans('Country') }}</label>
                </div>
                <div class="form-value">
                  <m-select
                    id="country"
                    name="country"
                    searchable
                    v-model="data.address.country"
                    @select="setValid('address.country')"
                    :options="countriesOptions"
                  />
                </div>
              </div>
            </template>
          </fieldset>

          <fieldset>
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>{{ trans('Product') }}</th>
                  <th class="text-right">{{ trans('Amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ course.title }}</td>
                  <td class="text-right whitespace-nowrap font-bold">{{ checkoutData.amount.net.display }}</td>
                </tr>
                <tr>
                  <td class="text-right">{{ trans('VAT') }}</td>
                  <td class="text-right whitespace-nowrap">{{ checkoutData.amount.vat.display }}</td>
                </tr>
                <tr>
                  <td class="text-right">{{ trans('Total') }}</td>
                  <td class="text-right whitespace-nowrap">{{ checkoutData.amount.gross.display }}</td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div class="text-right" v-html="terms"></div>
        </div>

        <div class="flex courses-center justify-end p-4" style="border-top: 1px solid rgba(0, 0, 0, 0.12)">
          <mdl-button type="button" @click="$emit('cancel')">{{ trans('Cancel') }}</mdl-button>
          <mdl-button type="submit" :disabled="purchasing" primary raised>{{ trans('Confirm order') }}</mdl-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    course: Object,
    requirementId: [Number, String],
  },

  watch: {
    course(val) {
      if (val) this.checkout()
    },
    'data.address.id'(val) {
      if (val === 'create') {
        this.setDefaultCountry()
      }
    },
  },

  data() {
    return {
      errors: [],
      company: null,
      fetching: false,
      purchasing: false,
      employing: false,
      checkoutData: null,
      data: {
        address: {
          id: 'create',
          line1: null,
          line2: null,
          postcode: null,
          city: null,
          country: null,
        },
        reference: null,
        email: null,
      },
      terms: trans(
        'By clicking ":button" below you accept our <a href=":link" target="_blank">terms & conditions</a>.',
        {
          link: '/no/shop/market/terms',
          button: trans('Confirm order'),
        },
      ),
    }
  },

  computed: {
    isVisible() {
      return this.course !== null
    },
    normalizedCourseImage() {
      if (!this.course) return null

      return `url(${this.course.object.image.header})`
    },
    companyAddresses() {
      if (!this.checkoutData) return []
      return this.checkoutData.invoice.addresses
    },
    addresses() {
      return [
        ...this.companyAddresses.map((a) => ({ label: [a.title, Munio.country(a.country).i18n].join(', '), id: a.id })),
        { label: trans('Create a new address'), id: 'create' },
      ]
    },
    isCustomAddress() {
      return this.data.address.id === 'create'
    },
    hasCompany() {
      return !!this.checkoutData && !!this.checkoutData.company
    },
    countriesOptions() {
      return Munio.countries().map((country) => ({
        id: country.iso,
        label: country.i18n,
      }))
    },
  },

  methods: {
    setValid(key) {
      let index = this.errors.indexOf(key)
      if (index === -1) return
      this.errors.splice(index, 1)
    },
    isValid(key) {
      return !this.errors.includes(key)
    },
    setDefaultCountry() {
      this.data.address.country = this.checkoutData.company
        ? this.checkoutData.company.country
        : Munio.config.i18n.country
    },
    employ() {
      this.employing = true

      Munio.api.currentUser.employ(this.company).then((res) => {
        this.employing = false
        this.checkout()
      })
    },
    checkout() {
      this.fetching = true
      Munio.api.currentUser.access
        .checkout(this.requirementId)
        .then((res) => (this.checkoutData = res.data))
        .then(() => {
          this.data.email = this.checkoutData.invoice.email
          this.data.reference = this.checkoutData.invoice.reference

          let address = this.companyAddresses.length
            ? this.companyAddresses.find((a) => a.lastUsed) || this.companyAddresses[0]
            : null

          if (address) this.data.address.id = address.id
          else {
            this.data.address.id = 'create'
            this.setDefaultCountry()
          }
        })
        .then(() => (this.fetching = false))
    },
    purchase() {
      this.purchasing = true

      Munio.api.currentUser.access
        .purchase(this.requirementId, {
          reference: this.data.reference,
          email: this.data.email,
          address: this.data.address.id === 'create' ? this.data.address : { id: this.data.address.id },
        })
        .then(() => {
          this.$emit('purchased')
          this.purchasing = false
          Munio.Flash.success(trans('Thank you for your order!'), { timer: 0, showConfirmButton: true })
        })
        .catch((res) => {
          this.purchasing = false
          if (res.response.status === 422) {
            this.errors = Object.keys(res.response.data.error.message)
          }
        })
    },
  },

  created() {
    if (this.course) this.checkout()
  },
}
</script>
