import './extensions.js'
import './globals.js'
import './dependencies.js'

// core
import './core.js'
import './storage.js'
import './http'
import './api/index'
import './behaviors/index.js'
import './vue/index.js'
import './vue/components/index.js'
import { setLocale } from './utils/date.js'

setLocale(Munio.config.i18n)

Munio.attachBehaviors(document)

/**
 * Tabs deep linking
 */
const url = document.location.toString()
if (url.match('#')) {
  $('a[href="#' + url.split('#')[1] + '"]').trigger('click')
}

/**
 * Mobile sidebar
 */
const $body = $('body')
const openClass = 'sidebar-out'

$('#navigation-toggle').on('click', function () {
  if ($body.hasClass(openClass)) {
    $body.removeClass(openClass)
  } else {
    $body.addClass(openClass)
  }
})

$('.page-left-backdrop').on('click', function (event) {
  if ($body.hasClass(openClass)) {
    $body.removeClass(openClass)
    event.preventDefault()
  }
})

/**
 * Currency decimal input
 */
$('input.input-currency-decimal').on('blur', function (e) {
  const $el = $(this)
  const value = $el.val()
  const maxLength = e.currentTarget.maxLength

  $el.val(value + Array(maxLength + 1 - value.length).join('0'))
})

/**
 * Zendesk Livechat
 */
if (Munio.config.support && Munio.config.user && Munio.config.user.employer) {
  ;(
    window.zEmbed ||
    function (source, domain) {
      let n, o, d, i, s
      const a = []
      const r = document.createElement('iframe')
      window.zEmbed = function () {
        a.push(arguments)
      }
      window.zE = window.zE || window.zEmbed
      r.src = 'javascript:false'
      r.title = ''
      r.role = 'presentation'
      ;(r.frameElement || r).style.cssText = 'display: none'
      d = document.getElementsByTagName('script')
      d = d[d.length - 1]
      d.parentNode.insertBefore(r, d)
      i = r.contentWindow
      s = i.document
      try {
        o = s
      } catch (e) {
        n = document.domain
        r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);'
        o = s
      }
      o.open()._l = function () {
        const o = this.createElement('script')
        n && (this.domain = n)
        o.id = 'js-iframe-async'
        o.src = source
        this.t = +new Date()
        this.zendeskHost = domain
        this.zEQueue = a
        this.body.appendChild(o)
      }
      o.write('<body onload="document._l();">')
      o.close()
    }
  )('https://assets.zendesk.com/embeddable_framework/main.js', 'munio.zendesk.com')

  window.zE(function () {
    window.zE.setLocale(Munio.config.i18n.locale)
    const user = Munio.config.user
    if (user.email) {
      window.zE.identify({
        name: user.name,
        email: user.email,
        organization: user.employer.name,
      })
    }
  })
}
