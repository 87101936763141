<template>
  <div class="input-quantity">
    <mdl-button icon :disabled="disabled || isMin" @click="decrement">
      <i class="material-icons md-18">remove</i>
    </mdl-button>

    <mdl-textfield
      name="quantity"
      type="number"
      :value="value"
      :min="min"
      :max="max"
      :disabled="disabled"
      @focus="handleFocus"
      @input="handleInput"
      @change="handleChange"
      @keyup.enter.prevent="$event.target.blur()"
    />

    <mdl-button icon :disabled="disabled || isMax" @click="increment">
      <i class="material-icons md-18">add</i>
    </mdl-button>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 1 },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 9999 },
    disabled: { type: Boolean },
  },

  computed: {
    isMin() {
      return this.value <= this.min
    },

    isMax() {
      return this.value >= this.max
    },
  },

  methods: {
    increment() {
      this.onChange(Math.min(this.value + 1, this.max))
    },

    decrement() {
      this.onChange(Math.max(this.value - 1, this.min))
    },

    handleFocus(event) {
      event.target.select()
    },

    handleInput(value) {
      this.onChange(value, false)
    },

    handleChange(event) {
      this.onChange(event.target.value)
    },

    onChange(value, change = true) {
      value = parseInt(value || 0, 10)
      if (value > this.max) value = this.max
      if (value < this.min) value = this.min
      this.$emit('input', value)
      if (change) {
        this.$emit('change', value)
      }
    },
  },
}
</script>
