import routes from './routes.js'

const state = {
  alert: Munio.state.alert,
  back: Object.assign({}, { url: null, title: null }, Munio.state.back),
  loading: false,
  transition: 'slide-next',
  currentIndex: 1,
  username: '',
  password: '',
  remember: false,
  language: { ...Munio.config.i18n },
  company: { ...Munio.config.company },
  user: null,
  userExists: !!Munio.config.user.id,
  userInvited: !!Munio.config.user?.isInvited,
  userAuthenticated: !!Munio.config.user.id,
  userInvite: Munio.state.userInvite,
  authenticated: false,
  employer: null,
  department: null,
  invite: null,
  errors: {},
  resetToken: Munio.state.resetToken,
}

const actions = {
  async checkUsername(store) {
    let username = store.state.username

    if (!username) {
      return
    }

    store.commit('loading', true)

    try {
      let { data } = await Munio.api.auth.checkUsername(username)

      store.commit('updateUsernameResult', data)

      if (data.result?.isInvited) {
        store.commit('navigate', 'registration')
      }
    } catch (e) {
      store.commit('updateUsernameError', trans('We were unable to find a user by that username.'))
    }

    store.commit('loading', false)
  },

  async cancelLogin({ state, commit }) {
    if (state.user) {
      await Munio.api.auth.forget()
      Munio.state.user = null
    }

    commit('reset')

    if (state.userInvite) {
      return this.$router.push({ name: 'invite.user', params: { invite: state.userInvite.uuid } })
    }
  },

  async login(store) {
    let { username, password, remember } = store.state
    let { query } = this.$router.currentRoute
    let options = { ...query, remember }

    if (!password) {
      return
    }

    if (store.state.userInvite) options.userInvite = store.state.userInvite.uuid
    if (store.state.invite) options.invite = store.state.invite.id

    try {
      store.commit('loading', true)
      store.dispatch('authenticate', await Munio.api.auth.login(username, password, options))
      store.commit('loading', false)
    } catch (e) {
      store.commit('updatePasswordError', trans('The password you entered is incorrect.'))
      store.commit('loading', false)
    }
  },

  async register(store, data) {
    data = { ...data }

    delete data.emailRepeat
    delete data.accepted

    if (store.state.userInvite) data.userInvite = store.state.userInvite.uuid
    if (store.state.invite) data.invite = store.state.invite.id

    if (this.employer) {
      data.company = { company: this.employer.id }
    }

    store.commit('loading', true)
    store.dispatch('authenticate', await Munio.api.auth.register(data))
    store.commit('loading', false)
  },

  async reset(store, password) {
    let token = store.state.resetToken.token

    try {
      store.commit('loading', true)
      store.dispatch('authenticate', await Munio.api.auth.reset(token, password))
    } catch (e) {
      console.error(e)
    } finally {
      store.commit('loading', false)
    }
  },

  authenticate(store, response) {
    if (response.status >= 200 && response.status < 300) {
      let { data, meta } = response.data

      if (meta.passwordToken) {
        meta.passwordToken.user = data

        store.commit('updateResetToken', meta.passwordToken)
        return this.$router.push({ name: 'reset', params: { token: meta.passwordToken.token } })
      }

      store.commit('setAuthenticated', data)
      store.commit('navigateToEntry', false)

      if (meta.redirect) {
        if (typeof meta.redirect === 'string') {
          window.location.href = meta.redirect
        } else {
          this.$router.push(meta.redirect)
        }
      }
      return
    }

    Munio.Flash.error(trans('Authentication failed'))
  },

  async acceptUserInvite(store) {
    store.commit('loading', true)
    await Munio.api.userInvite(store.state.userInvite.uuid).accept()
  },

  async declineUserInvite(store) {
    store.commit('loading', true)
    await Munio.api.userInvite(store.state.userInvite.uuid).decline()
  },

  async clearUser(store) {
    await Munio.api.auth.forget()
    Munio.state.user = null
    store.commit('reset')
  },
}

const mutations = {
  selectLanguage(state, language) {
    state.language = language
  },

  loading(state, value) {
    state.loading = value
  },

  reset(state) {
    state.username = ''
    state.password = ''
    state.user = null
    state.userExists = false
    state.userAuthenticated = !!Munio.config.user.id
    state.department = null
    state.errors = {}
    state.resetToken = Munio.state.resetToken
    state.authenticated = false

    if (Munio.state.user) {
      state.user = Munio.state.user
      state.username = state.user.email || state.user.mobile
      state.userExists = true
    }

    if (Munio.state.userInvite) {
      state.userInvite = { ...Munio.state.userInvite }
      state.company = Munio.state.userInvite.company
      state.user = Munio.state.userInvite.user
      state.username = Munio.state.userInvite.user.email
      state.userExists = true
    } else if (Munio.state.invite) {
      state.invite = { ...Munio.state.invite }
      state.company = Munio.state.invite.ownerCompany
      state.employer = Munio.state.invite.company
    }
  },

  clearUser(state) {
    state.username = ''
    state.user = null
    state.userExists = false
    state.userAuthenticated = false
  },

  setAuthenticated(state, user) {
    state.user = user
    state.userExists = true
    state.userAuthenticated = true
    state.authenticated = true

    if (state.userInvite && state.userInvite.user.id !== user.id) {
      state.userInvite = null
    }
  },

  updateResetToken(state, token) {
    state.resetToken = token
  },

  updateUsername(state, value) {
    state.username = value
    state.errors = { ...state.errors, username: null }
  },

  updateUsernameError(state, value) {
    state.userExists = false
    state.errors = { ...state.errors, username: value.replace(/\.$/g, '') }
  },

  updatePassword(state, value) {
    state.password = value
    state.errors = { ...state.errors, password: null }
  },

  updatePasswordError(state, value) {
    state.errors = { ...state.errors, password: value.replace(/\.$/g, '') }
  },

  updateRemember(state, value) {
    state.remember = value
  },

  updateUsernameResult(state, data) {
    state.errors = {
      ...state.errors,
      username: null,
      password: null,
    }

    switch (data.type) {
      case 'user':
        state.userExists = true
        state.userInvited = data.result?.isInvited || false

        if (data.result?.id) {
          state.user = data.result
        }
        break

      case 'invite':
        // todo: simplify this by only setting the invite property
        let { ownerCompany, company } = data.result
        state.invite = data.result
        state.company = ownerCompany
        state.employer = company
        break
    }
  },

  setInvite(state, invite) {
    state.invite = invite
  },

  navigateToEntry(state, goToLogin = true) {
    if (state.userInvite) {
      state.transition = 'slide-back'
      this.$router.push({ name: 'invite.user', params: { invite: state.userInvite.uuid } })
    } else if (goToLogin) {
      state.transition = 'slide-next'
      this.$router.push({ name: 'login' })
    }
  },

  transition(state, next) {
    state.transition = next ? 'slide-next' : 'slide-back'
  },

  navigate(state, name) {
    let route = routes.find((route) => route.name === name)
    let nextIndex = routes.indexOf(route)

    state.alert = null
    state.transition = state.currentIndex < nextIndex ? 'slide-next' : 'slide-back'
    state.currentIndex = nextIndex

    this.$router.push({ name })
  },
}

export default {
  state,
  actions,
  mutations,
}
