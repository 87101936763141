import { ShopCartResourceDto } from '@/munio/api/data/ShopCartResource.dto'
import { CollectionResponse, ResourceResponse } from '@/munio/api/data/common'
import { CompanyBasicResourceDto, UserBasicResourceDto } from '@/munio/api/data'
import { CompanyAddressDto } from '@/munio/api/data/CompanyResource.dto'

const { route, $http } = window.Munio
const cleanup = window.cleanup

class ShopCart {
  constructor(protected shop: string) {}

  protected route(name: string, params: object = {}) {
    return route(name, {
      shop: this.shop,
      ...params,
    })
  }

  updateCompany(company: Partial<CompanyBasicResourceDto>, setAsEmployer: boolean = false) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.company'), {
      company,
      set_as_employer: setAsEmployer,
    })
  }

  updateAddress(address: CompanyAddressDto) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.address'), {
      address,
    })
  }

  updateEnrollment(enrollments: ItemEnrollment[]) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.enrollment'), {
      enrollments,
    })
  }

  updatePayment(method: string, address?: CompanyAddressDto, data?: any) {
    return $http.put<ResourceResponse<AuthenticatedCart>>(this.route('api.web.shop.checkout.update.payment'), {
      method,
      address,
      data,
    })
  }

  confirm() {
    return $http.post<{ redirect: string }>(this.route('api.web.shop.checkout.confirm'))
  }

  search(userInput: any) {
    return $http.post<CollectionResponse<UserBasicResourceDto>>(
      this.route('api.web.shop.checkout.search'),
      cleanup(userInput),
    )
  }
}

export default ShopCart

type NewUser = {
  id?: number
  firstname?: string
  lastname?: string
  email: string
  company?: CompanyBasicResourceDto
}

type ExistingUser = NewUser & {
  id: number
  email?: string
}

export type NewOrExistingUser = NewUser | ExistingUser

export type ItemEnrollment = {
  item: number
  users: Array<NewOrExistingUser>
}

export type AuthenticatedCart = ShopCartResourceDto & {
  user: UserBasicResourceDto
}
