<template>
  <modal :show="show" @hide="cancel" :loading="processing">
    <div
      class="coursemodal__header px-8 py-12 flex flex-col justify-end"
      :style="courseImageStyle"
      @dblclick="fetchCourse(true)"
    >
      <div v-if="subtitle" class="opaque" style="margin-bottom: 5px">{{ subtitle }}</div>
      <h3>{{ title }}</h3>
    </div>

    <button class="coursemodal__cancel" @click="cancel">
      <span class="material-icons">close</span>
    </button>

    <mdl-progressbar v-if="fetching" indeterminate />

    <buy-course
      v-else-if="purchase"
      :course="course"
      :requirement-id="requirementId"
      @cancel="purchase = false"
      @purchased="fetchCourse(true)"
    />

    <template v-else-if="course">
      <info v-if="course.info?.length" :header="trans('Participant information')">{{ course.info }}</info>

      <div v-if="course.isIlt">
        <content-ilt :userCourse="userCourse" @updateSession="updateSession" />
      </div>

      <template v-else-if="course.isMisc">
        <content-misc
          :userCourse="userCourse"
          :loading="processing"
          :on-upload="onMiscUpload"
          :on-setdate="onMiscSetDate"
          :on-approve="onMiscApprove"
          @unenroll="unenroll"
          @enroll="enroll"
          @restart="restart"
        />
      </template>

      <content-course
        v-else
        :userCourse="userCourse"
        :product="product"
        :processing="processing"
        @start="start"
        @unenroll="unenroll"
        @enroll="enroll"
        @purchase="purchase = true"
      />
    </template>
    <template #footer>
      <mdl-button v-if="course?.isMisc && !fetching" :disabled="processing" @click="cancel">
        {{ trans('Close') }}
      </mdl-button>
    </template>
  </modal>
</template>

<script>
import BuyCourse from './BuyCourse.vue'
import ContentCourse from './Content/Course.vue'
import ContentIlt from './Content/ILT.vue'
import ContentMisc from './Content/Misc.vue'
import Info from './Content/Info.vue'

export default {
  name: 'CourseLauncherModal',

  components: {
    Info,
    BuyCourse,
    ContentCourse,
    ContentIlt,
    ContentMisc,
  },

  props: {
    show: { type: Boolean, required: true },
    id: { type: [String, Number] },
    requirementId: { type: [String, Number] },
    image: { type: String },
    title: { type: String },
    subtitle: { type: String },
    context: { type: [String, Number] },
    selfEnroll: Boolean,
  },

  data() {
    return {
      purchase: false,
      fetching: false,
      processing: false,
      userCourse: null,
      modified: false,
    }
  },

  computed: {
    course() {
      if (!this.userCourse) {
        return
      }

      return this.userCourse.course
    },

    courseImageStyle() {
      if (!this.image) return null

      return {
        backgroundImage: `url(${this.image})`,
      }
    },

    product() {
      if (!this.userCourse) {
        return
      }

      return this.userCourse.product
    },
  },

  methods: {
    cancel() {
      if (this.show) {
        this.$emit('cancel')
        if (this.modified && !this.$store) {
          window.location.reload(true)
        }
      }
    },

    showErrorPopup() {
      Munio.Flash.error(trans('An unknown error has occured') + '. ' + trans('Please try again') + '.')
    },

    async start({ course: userCourse, restart = false }) {
      try {
        this.processing = true
        await Munio.api.currentUser.courses.launch(userCourse.courseId, this.context, restart)
      } catch (err) {
        this.showErrorPopup()
      } finally {
        this.processing = false
      }
    },

    async restart() {
      if (!(await Munio.confirm())) {
        return
      }

      try {
        this.processing = true
        const { data } = await Munio.api.currentUser.courses.restart(this.userCourse.courseId)
        this.setCourse(data)
      } catch (err) {
        this.showErrorPopup()
      } finally {
        this.processing = false
      }
    },

    async onMiscUpload(file) {
      if (!file) return

      try {
        this.processing = true
        const { data } = await Munio.api.currentUser.courses.document(this.userCourse.courseId, file)
        this.setCourse(data)
      } catch (err) {
        this.showErrorPopup()
      } finally {
        this.processing = false
      }
    },

    async onMiscSetDate(dateFrom, dateTo) {
      if (!dateFrom && !dateTo) return

      try {
        this.processing = true
        const { data } = await Munio.api.currentUser.courses.setdate(this.userCourse.courseId, dateFrom, dateTo)
        this.setCourse(data)
      } catch (err) {
        this.showErrorPopup()
      } finally {
        this.processing = false
      }
    },

    async onMiscApprove() {
      try {
        this.processing = true
        const { data } = await Munio.api.currentUser.courses.approve(this.userCourse.courseId)
        this.setCourse(data)
      } catch (err) {
        this.showErrorPopup()
      } finally {
        this.processing = false
      }
    },

    async unenroll(userCourse) {
      if (await Munio.confirm()) {
        try {
          this.processing = true
          await Munio.api.currentUser.courses.unenroll(userCourse.courseId)
          this.modified = true
          this.cancel()
        } catch (err) {
          console.error(err)
        } finally {
          this.processing = false
        }
      }
    },

    async enroll(userCourse) {
      try {
        this.processing = true
        await Munio.api.currentUser.courses.enroll(userCourse.courseId)
        this.modified = true
        await this.fetchCourse(true, false)
      } catch (err) {
        console.error(err)
      } finally {
        this.processing = false
      }
      // window.location.reload(true)
    },

    async updateSession(session) {
      if (this.$store) {
        this.$store.commit('setRequirementSession', { id: this.requirementId, session })
      }

      this.modified = true

      if (!session) {
        this.cancel()
      }

      await this.fetchCourse(true)
    },

    setCourse(course) {
      this.userCourse = course
    },

    async fetchCourse(force = false, selfEnroll = null) {
      if (!this.show) {
        return
      }

      if (!this.id) {
        return
      }

      if (this.course) {
        if (!force && this.course.id === this.id) {
          return
        }
      }

      this.fetching = true

      const { data } = await Munio.api.currentUser.courses.get(this.id, this.context)

      if (selfEnroll === null) {
        selfEnroll = this.selfEnroll
      }

      data.canSelfEnroll = selfEnroll

      this.purchase = false
      this.userCourse = data
      this.fetching = false
    },
  },

  watch: {
    show: {
      handler() {
        this.fetchCourse()
      },
      immediate: true,
    },
  },
}
</script>
