<template>
  <form class="flex flex-col flex-auto justify-between" @submit.prevent="next" autocomplete="on">
    <div>
      <Profile v-if="userExists" />

      <MdlTextfield
        v-else
        ref="username"
        class="block"
        name="username"
        autocomplete="email"
        :floatingLabel="trans('E-mail or mobile')"
        :error="usernameError"
        v-model="username"
        v-autofocus
      />

      <div v-if="userExists">
        <MdlTextfield
          ref="password"
          class="block"
          type="password"
          name="password"
          autocomplete="password"
          :floatingLabel="trans('Password')"
          :error="passwordError"
          v-model="password"
        />

        <div class="flex flex-wrap gap-y-4 justify-between">
          <label class="input-checkbox input-remember">
            <input type="checkbox" v-model="remember" :data-qa-id="pendo('rememeberMe')" />
            {{ trans('Remember me') }}
          </label>
          <div
            class="inline-block mdl-color-text--primary cursor-pointer self-end"
            @click="recover"
            :data-qa-id="pendo('forgotPassword')"
          >
            {{ trans('Forgot your password?') }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!userExists" class="flex flex-col">
      <MdlButton key="next" type="submit" raised primary :data-qa-id="pendo('continue')">
        {{ trans('Continue') }}
      </MdlButton>

      <MdlDivider :label="trans('New to Munio?')" class="my-4" />

      <MdlButton key="account" opaque primary @click="createAccount" :data-qa-id="pendo('createAccount')">
        {{ trans('Create account') }}
      </MdlButton>
    </div>

    <div v-else class="action-bar">
      <MdlButton key="next" type="submit" raised primary :data-qa-id="pendo('logIn')">
        {{ trans('Log in') }}
      </MdlButton>

      <MdlButton key="back" @click="cancel" :data-qa-id="pendo('back')">
        {{ trans(user ? 'Back' : 'Cancel') }}
      </MdlButton>
    </div>
  </form>
</template>

<script>
import MdlButton from '@component/mdl/Button.vue'
import MdlDivider from '@component/mdl/Divider.vue'
import MdlTextfield from '@component/mdl/Textfield.vue'
import Profile from './Profile.vue'

export default {
  components: {
    MdlButton,
    MdlDivider,
    MdlTextfield,
    Profile,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { user, userAuthenticated, userInvite } = vm.$store.state

      if (userInvite && !userAuthenticated && user && user.isInvited) {
        vm.$router.push({ name: 'invite.user.registration', params: { invite: userInvite.uuid } })
      }
    })
  },

  computed: {
    transition: {
      get() {
        return this.$store.state.transition
      },
      set(value) {
        this.$store.commit('transition', value)
      },
    },

    user() {
      return this.$store.state.user
    },

    userExists() {
      return this.$store.state.userExists && !this.$store.state.userInvited
    },

    userAuthenticated() {
      return this.$store.state.authenticated
    },

    username: {
      get() {
        return this.$store.state.username
      },
      set(value) {
        this.$store.commit('updateUsername', value)
      },
    },

    usernameError() {
      return this.$store.state.errors.username
    },

    password: {
      get() {
        return this.$store.state.password
      },

      set(value) {
        this.$store.commit('updatePassword', value)
      },
    },

    passwordError() {
      return this.$store.state.errors.password
    },

    remember: {
      get() {
        return this.$store.state.remember
      },

      set(value) {
        this.$store.commit('updateRemember', value)
      },
    },
  },

  methods: {
    cancel() {
      this.$store.dispatch('cancelLogin')
    },

    recover() {
      this.$store.commit('navigate', 'recover')
    },

    async verify() {
      this.transition = true
      await this.$store.dispatch('checkUsername')
      this.$nextTick(() => {
        if (this.userExists) {
          this.$refs.password?.focus()
        }
      })
    },

    createAccount() {
      this.$store.commit('navigate', 'registration')
    },

    async next() {
      if (this.userExists) {
        return this.$store.dispatch('login')
      } else if (!this.username) {
        return this.$store.commit('updateUsernameError', trans('Required'))
      }

      this.verify()
    },
  },
}
</script>
