import dateIsAfter from 'date-fns/isAfter'
import dateStartOfWeek from 'date-fns/startOfISOWeek'
import dateStartOfMonth from 'date-fns/startOfMonth'
import dateEndOfWeek from 'date-fns/endOfISOWeek'
import dateEndOfMonth from 'date-fns/endOfMonth'
import { parseDate, toDateString } from '@/munio/utils/date.js'
import InputForm from './Forms/Input.vue'
import RadioForm from './Forms/Radio.vue'
import CheckboxForm from './Forms/Checkbox.vue'
import DateRangeForm from './Forms/DateRange.vue'
import SwitchForm from './Forms/Switch.vue'
import cookies from 'js-cookie'

export const empty = [null, undefined, '']

export const typeForms = {
  input: InputForm,
  radio: RadioForm,
  checkbox: CheckboxForm,
  period: DateRangeForm,
  daterange: DateRangeForm,
  switch: SwitchForm,
}

export function values({ type, name, value }) {
  const values = []

  switch (type) {
    case 'checkbox':
      value = value || []
      value.forEach((val) => {
        values.push({
          name: name + '[]',
          value: val,
        })
      })
      break

    case 'period':
    case 'daterange':
      value = value || { from: '', to: '' }

      if (value.from) {
        values.push({ name: name + '[from]', value: value.from })
      }

      if (value.to) {
        values.push({ name: name + '[to]', value: value.to })
      }
      break

    default:
      values.push({ name, value })
      break
  }

  return values
}

export function valueDefault(filter) {
  switch (filter.type) {
    case 'bool':
      return false
    case 'switch':
      return true
    case 'radio':
      if (filter.options.length > 0) {
        return filter.options[0].value ?? ''
      }
      return ''
    case 'checkbox':
      return []
    case 'period':
    case 'daterange':
      return { from: '', to: '' }
    case 'input':
    default:
      return ''
  }
}

export function valueDisplay({ type, value, options }) {
  if (value === null) {
    return undefined
  }

  switch (type) {
    case 'input':
      return `"${value}"`

    case 'bool':
      return undefined

    case 'switch':
      return trans(value ? 'Yes' : 'No')

    case 'period':
    case 'daterange': {
      if (value.from && value.to) {
        return [value.from, value.to].filter((v) => v).join(' → ')
      }

      if (value.from) {
        return `${value.from} →`
      }

      if (value.to) {
        return `→ ${value.to}`
      }

      return undefined
    }

    case 'radio': {
      if (value) {
        const option = options.find((option) => String(option.value) === String(value))

        if (option) {
          return `"${option.label}"`
        }
      }
      return undefined
    }

    case 'checkbox': {
      const numSelected = value.length
      if (numSelected > 1) {
        return trans_choice(':num selected', numSelected)
      }
      if (numSelected === 1) {
        const option = options.find((option) => value.includes(option.value) || value.includes(String(option.value)))

        if (option) {
          return `"${option.label}"`
        }
      }
      return undefined
    }
  }
}

export function extractQueryParams(filters, queryString) {
  const active = []
  const values = {}
  const data = Munio.urlParams(queryString)
  const search = data.get('search') || ''

  for (const filter of filters) {
    let value = null

    switch (filter.type) {
      case 'checkbox':
        value = data.getAll(filter.name + '[]') || filter.valueDefault || []
        if (value.length > 0) {
          values[filter.name] = value
          active.push(filter.name)
        }
        break
      case 'switch':
        if (!empty.includes(data.get(filter.name))) {
          value = data.get(filter.name) === 'true'
          values[filter.name] = value
          active.push(filter.name)
        }
        break
      case 'period':
      case 'daterange':
        value = {
          from: data.get(filter.name + '[from]'),
          to: data.get(filter.name + '[to]'),
        }
        if (empty.includes(value.from) && empty.includes(value.to)) {
          value = filter.valueDefault || { from: '', to: '' }
        }
        if (!empty.includes(value.from) || !empty.includes(value.to)) {
          values[filter.name] = value
          active.push(filter.name)
        }
        break

      default:
        value = data.get(filter.name) || filter.valueDefault
        if (!empty.includes(value)) {
          values[filter.name] = value
          active.push(filter.name)
        }
        break
    }
  }

  return {
    active,
    values,
    search,
  }
}

export function filterValue(filter, form, extraActive) {
  if (typeof filter.extraInput === 'object') {
    const value = extractExtraFilterValue(filter, form, extraActive)
    const isPropUndefined = (obj, prop) => Object.hasOwn(obj, prop) && obj[prop] === undefined

    if (value === undefined || (isPropUndefined(value, 'value') && isPropUndefined(value, 'extraValue'))) {
      return undefined
    }

    return value
  }

  return extractFilterValue(filter, form, 'value')
}

function extractExtraFilterValue(filter, form, extraActive) {
  const value = extractFilterValue(filter, form, 'value')
  const extraValue = extractFilterValue(filter.extraInput, form, 'extraValue')

  if (filter.extraInput.allowMultipleInput) {
    return {
      value,
      extraValue,
    }
  }

  if (!extraActive) {
    return value
  }

  if (extraValue === undefined) {
    return undefined
  }

  return { extraValue }
}

export function extractFilterValue(filter, form, name) {
  const data = new FormData(form)
  let value = data.get(name)
  let isEmpty = false

  switch (filter.type) {
    case 'checkbox':
      value = data.getAll(name)
      isEmpty = !value.length
      break
    case 'switch':
      value = value === 'on'
      break
    case 'period':
      break
    case 'daterange':
      value = {
        from: data.get(`${name}_from`),
        to: data.get(`${name}_to`),
      }

      if (dateIsAfter(parseDate(value.from), parseDate(value.to))) {
        value = {
          from: value.to,
          to: value.from,
        }
      }

      if (filter.type === 'period' && ['month', 'week'].includes(filter.unit)) {
        const fromDateModifier = filter.unit === 'month' ? dateStartOfMonth : dateStartOfWeek
        const toDateModifier = filter.unit === 'month' ? dateEndOfMonth : dateEndOfWeek
        value.from = toDateString(fromDateModifier(value.from))
        value.to = toDateString(toDateModifier(value.to))
      }

      isEmpty = !value.from && !value.to
      break

    default:
      isEmpty = value === null || !value.length
      break
  }

  return isEmpty ? undefined : value
}

export function resetSelection() {
  cookies.set('selection', 'clear:filters', { domain: '.' + Munio.config.domain, path: '/' })
}
