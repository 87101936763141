import { Translation } from '@/munio.common'
import { UserBasicResourceDto } from './data/UserBasicResource.dto'

export default class Course {
  constructor(public id: number) {
    this.id = id
  }

  public async get() {
    const route = window.Munio.route('api.web.admin.course.show', {
      course: this.id,
    })

    return window.Munio.$http.get<CourseResourceDto>(route)
  }

  public async revert() {
    const route = window.Munio.route('api.web.admin.course.revert', {
      course: this.id,
    })

    return window.Munio.$http.post<CourseResourceDto>(route)
  }

  public async publish(isMajor: boolean, changelog?: string) {
    const route = window.Munio.route('api.web.admin.course.publish', {
      course: this.id,
    })

    return window.Munio.$http.post<CourseResourceDto>(route, { isMajor, changelog })
  }

  public save(course: object) {
    const route = window.Munio.route('api.web.admin.course.update', {
      course: this.id,
    })

    return window.Munio.$http.post<CourseResourceDto>(route, course)
  }

  public async saveImage(file: File) {
    const route = window.Munio.route('api.web.admin.course.image.update', {
      course: this.id,
    })

    const form = new FormData()
    form.append('image', file, file.name)
    const response = await window.Munio.$http.post(route, form)
    return response.data
  }
}

export type RevisionDto = {
  current: number | null
  draft: number | null
  major: number | null
}

export type FileDto = {
  name: string
  extension: string
  size: number
  exists: boolean
  mime: string
  timestamp: string
}

export type ValidityMode = 'from' | 'to' | 'period'

export type CourseType = 'course' | 'ilt' | 'misc'

export type ObjectType =
  | CourseType
  | 'tincan'
  | 'scorm'
  | 'colibri'
  | 'epub'
  | 'pdf'
  | 'powerpoint'
  | 'confirmation'
  | 'video'
  | 'link'

export type ObjectDto = {
  id: number | null
  ulid: string
  revision: RevisionDto
  type: ObjectType
  typeName: string
  typeIcon: string
  languages: string[]
  title: Translation
  description: Translation
  metadata?: object | null
  duration: {
    minutes: number
    human: string | null
    short: string | null
  }
  maxFailed: number | null
  documentation: {
    enabled: boolean
    required: boolean
  }
  approval: {
    required: boolean
  }
  validityMode: ValidityMode | null
  resources: Record<string, ResourceDto>
  hasSubcourse: boolean
  isSubcourse: boolean
  isOptional: boolean
  isMonetized: boolean
  isAdminResponsible: boolean
  identifier: string
  image: ImageDto
}

export type ResourceDto = {
  id: number
  ulid: string
  title: string | null
  file: FileDto
  metadata: any
  downloadUrl: string
  isAttachment: boolean
  isLink: boolean
  isArchive: boolean
  isValid: boolean
  createdAt: string
  updatedAt: string
}

export type ConditionDto = {
  type: 'auth' | 'dob' | 'mobile' | 'recert' | 'certificate'
  options: null | {
    repeat?: boolean
    optional?: boolean
    certificateTypeId?: number
  }
}

export type NodeDto = {
  id: number
  objectId: number
  objectRevisionId: number
  object: ObjectDto | CourseDto
}

export type ImageDto = {
  exists: boolean
  avatar: string
  small: string
  medium: string
  large: string
  header: string
}

export type CourseDto = ObjectDto & {
  identifier: string | null
  badges: string[]
  description: Translation
  diplomaDescription: Translation
  info: Translation
  image: ImageDto
  version: string
  canPublish: boolean
  canRevert: boolean
  createdBy?: UserBasicResourceDto
  modifiedBy?: UserBasicResourceDto
  conditions?: ConditionDto[]
  attachments?: ResourceDto[]
  nodes?: NodeDto[]
}

export type CertificateTypeDto = {
  id: number
  type: string
  name: string
}

export type CourseResourceDto = {
  data: CourseDto
  uploads?: Record<string, string>
}
