<template>
  <div v-if="showButton" :class="wrapperClasses" v-click-outside="hideMenu" v-focus-outside="hideMenu">
    <slot name="button" :toggleMenu="toggleMenu" :active="active">
      <mdl-button :icon="buttonIcon" :raised="buttonRaised" @click.stop="toggleMenu" />
    </slot>
    <ul :class="menuClasses" @click.stop="hideMenu" v-bind="$attrs" v-on="$listeners" role="menu" :style="menuStyle">
      <slot></slot>
    </ul>
  </div>

  <ul v-else :target="target" :class="menuClasses" v-bind="$attrs" v-on="$listeners" role="menu" :style="menuStyle">
    <slot></slot>
  </ul>
</template>

<script>
let lastMenu
export default {
  inheritAttrs: false,
  props: {
    target: { type: String, required: false },
    right: { type: Boolean, default: false },
    top: Boolean,
    button: { type: Boolean, default: false },
    buttonIcon: { type: String, default: 'more_vert' },
    buttonRaised: { type: Boolean },
  },

  data() {
    return {
      active: false,
    }
  },

  computed: {
    showButton() {
      return this.button && this.$slots.default
    },

    wrapperClasses() {
      return classNames('dropdown inline-block', {
        open: this.active,
      })
    },

    menuClasses() {
      return classNames('dropdown-menu', {
        'dropdown-menu-right': this.right,
      })
    },

    menuStyle() {
      if (this.top) {
        return 'bottom:100%;top:auto;'
      }
      return ''
    },
  },

  methods: {
    hideMenu() {
      this.active = false
    },
    toggleMenu() {
      if (lastMenu && lastMenu !== this) {
        lastMenu.hideMenu()
      }

      lastMenu = this
      this.active = !this.active
    },
  },
}
</script>
