<template>
  <div class="max-w-full">
    <dt class="mdl-overline">{{ term }}</dt>
    <dd class="flex mdl-color-text--grey-900 select-all" :class="{ truncate, 'whitespace-normal': multiline }">
      <template v-if="isDescriptionTooLong">
        <div class="flex-wrap">
          <span>{{ descriptionParts[0] }}</span>
          <span class="opaque" v-if="can('munio')">
            {{ descriptionParts[1] }}
            <icon name="warning" small class="mdl-color-text--danger" />
          </span>
        </div>
      </template>
      <template v-else>
        {{ description || ($slots.default ? '' : placeholder) }}
      </template>
      <slot></slot>
    </dd>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    term: { type: String, required: true },
    description: { type: [Number, String, null, undefined] },
    placeholder: { type: String, default: '–' },
    multiline: { type: Boolean },
    truncate: { type: Boolean },
    length: { type: Number },
  },

  computed: {
    isDescriptionTooLong() {
      return this.description && this.length && this.description.length > this.length
    },

    descriptionParts() {
      if (this.isDescriptionTooLong) {
        return [String(this.description).substring(0, this.length), String(this.description).substring(this.length)]
      }

      return [this.description || this.placeholder, undefined]
    },
  },
})
</script>
