<template>
  <mdl-textfield
    ref="textfield"
    v-model="displayValue"
    v-bind="$attrs"
    v-on="listeners"
    @blur="onBlur"
    @focus="onFocus"
    align="right"
  >
    <slot></slot>
  </mdl-textfield>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    currency: { type: String, required: true },
    value: { type: Number, required: true },
  },

  data() {
    return {
      input: String(this.value),
      parsedValue: '',
      focused: false,
    }
  },

  computed: {
    listeners() {
      const { input, blur, focus, ...events } = this.$listeners

      return events
    },

    displayValue: {
      get() {
        return this.focused
          ? this.input // .replace(/[^0-9\s,.\u066b\u2396·]/, '')
          : Munio.Currency.format(this.value, this.currency)
      },

      set(value) {
        this.input = value
        this.parsedValue = Munio.Currency.parse(value)
        this.$emit('input', this.parsedValue)
      },
    },
  },

  methods: {
    onFocus(e) {
      this.focused = true
      this.$emit('focus', e)
      this.$nextTick(() => {
        this.$refs.textfield.$refs.input.select()
      })
    },

    onBlur(e) {
      this.focused = false
      this.$emit('blur', e)
    },
  },

  watch: {
    value(value, oldValue) {
      if (!this.focused) {
        this.input = String(value)
      }
    },
  },
}
</script>
