<template>
  <MdlListItem
    :thumbnail="model && model.thumbnail"
    :badge="action.direction === 'out' ? 'arrow_forward' : 'arrow_back'"
    :badge-classes="action.direction === 'out' ? 'mdl-color-text--primary' : 'mdl-color-text--accent'"
    :progress="progress && progress < 100 ? progress : undefined"
    :background="getStateColor(action.state)"
    :initials="action.typeName.replace(/[a-z]+/g, '').substring(0, 2)"
    :expandable="expandable"
    :expanded="selected"
    :loading="loading"
    :modified="action.modified"
    body-inset
    @toggle="onToggle"
  >
    <template #title>
      <span>{{ action.description }}</span>
      <span v-if="model && model.label" class="label label-label-default mdl-color--primary mx-2">
        {{ model.label }}
        <Icon v-if="model.icon" :name="model.icon" small />
        <span v-if="model.key">
          <span v-if="!model.icon">#</span>
          {{ model.key }}
        </span>
      </span>
    </template>

    <template #subtitle>
      {{ action.integration.typeName }}.{{ `${action.typeName}(${action.argumentsString})` }}
    </template>

    <template #actions>
      <User v-if="action.creator" :user="action.creator" right class="mr-4" />

      <div class="flex flex-col">
        <span class="label label-default text-uppercase" :class="getStateLabelClasses(action.state)">{{
          action.state
        }}</span>
        <Date time :value="action.createdAt" />
      </div>
    </template>

    <template #body>
      <div class="flex flex-col gap-4 p-[16px]">
        <DefinitionList>
          <DefinitionItem :term="trans('ID')" class="font-mono font-bold">{{ action.ulid }}</DefinitionItem>
          <DefinitionItem :term="trans('Source')">{{
            trans(action.direction === 'out' ? 'Munio' : action.integration.typeName)
          }}</DefinitionItem>
          <DefinitionItem v-if="action.progress.total" :term="trans('Progress')">{{
            `${action.progress.current} / ${action.progress.total} (${Math.ceil(
              (action.progress.current / action.progress.total) * 100,
            )}%)`
          }}</DefinitionItem>
        </DefinitionList>

        <div v-if="can('munio') && action.error" class="alert alert-danger whitespace-pre-wrap m-0">
          {{ action.error }}
        </div>

        <div v-if="!loading && !action.events?.length" class="alert alert-info m-0">{{ trans('No events') }}</div>
      </div>

      <MdlTable v-if="action.events?.length">
        <MdlTableHeader>
          <th>{{ trans('Event') }}</th>
          <th>{{ trans('Date') }}</th>
          <th />
        </MdlTableHeader>

        <MdlTableRow v-for="actionEvent of action.events" :key="actionEvent.ulid" :data-ulid="actionEvent.ulid">
          <template v-if="actionEvent.eventType === 'integration_action'">
            <td>
              <div class="flex items-center gap-2">
                <icon name="settings_ethernet" v-tooltip="trans('Action')" />
                <span
                  >{{ actionEvent.event.integration.typeName }}.{{
                    `${actionEvent.event.typeName}(${actionEvent.event.argumentsString})`
                  }}</span
                >
              </div>
            </td>
            <td>
              <Date :value="actionEvent.event.createdAt" time />
            </td>
            <td>
              <Icon :name="getStateIcon(actionEvent.event.state)" v-tooltip="titleCase(actionEvent.event.state)" />
            </td>
          </template>
          <template v-else-if="actionEvent.eventType === 'integration_action_request'">
            <td>
              <div class="flex items-center gap-2">
                <icon name="keyboard_double_arrow_right" v-tooltip="trans('Request')" />
                <strong class="text-uppercase">{{ actionEvent.event.method }}</strong>
                <span>{{ actionEvent.event.url }}</span>
              </div>
            </td>
            <td>
              <Date :value="actionEvent.event.createdAt" time />
            </td>
            <td>
              <Icon
                v-if="actionEvent.event.response"
                :name="actionEvent.event.response.isSuccess ? 'done' : 'error_outline'"
                v-tooltip="actionEvent.event.response.status + ' ' + actionEvent.event.response.statusText"
              />
              <Icon v-else name="radio_button_unchecked" />
            </td>
          </template>
        </MdlTableRow>

        <tfoot>
          <tr>
            <td colspan="5">
              <MdlPaginator
                v-if="action.eventsMeta"
                :loading="paginating"
                :value="action.eventsMeta"
                @change="$emit('paginate', $event)"
              />
            </td>
          </tr>
        </tfoot>
      </MdlTable>
    </template>

    <template v-if="action.canCancel || action.canRetry" #card-actions>
      <MdlButton
        v-if="action.canCancel"
        :disabled="action.state === 'cancelled'"
        :loading="cancelling"
        outlined
        primary
        @click.stop="onCancel"
        >{{ trans('Cancel') }}
      </MdlButton>

      <MdlButton
        v-if="action.canRetry"
        :disabled="action.state !== 'failed'"
        :loading="retrying"
        raised
        primary
        @click.stop="onRetry"
        >{{ trans('Retry') }}
      </MdlButton>
    </template>
  </MdlListItem>
</template>

<script>
import { computed, ref } from 'vue'
import { titleCase } from '@/munio/utils/index.js'
import { stateColor, stateIcon, stateLabelClasses } from './helpers'
import useStore from './store.js'
import Date from '@component/Date.vue'
import DefinitionList from '@component/DefinitionList.vue'
import DefinitionItem from '@component/DefinitionItem.vue'
import Icon from '@component/Icon.vue'
import MdlButton from '@component/mdl/Button.vue'
import MdlListItem from '@component/mdl/ListItem.vue'
import MdlPaginator from '@component/mdl/Paginator.vue'
import MdlTable from '@component/mdl/DataTable.vue'
import MdlTableHeader from '@component/mdl/DataTableHeader.vue'
import MdlTableRow from '@component/mdl/DataTableRow.vue'
import User from '@component/User.vue'

export default {
  name: 'IntegrationAction',
  components: {
    Date,
    DefinitionItem,
    DefinitionList,
    Icon,
    MdlButton,
    MdlListItem,
    MdlPaginator,
    MdlTable,
    MdlTableHeader,
    MdlTableRow,
    User,
  },

  props: {
    action: { type: Object, required: true },
    hideModel: { type: Boolean },
  },

  setup(props) {
    const store = useStore()
    const model = computed(() => {
      if (props.hideModel) {
        return undefined
      }

      return props.action.model
    })
    const selected = computed(() => {
      return props.action.ulid === store.actions.selected
    })

    const cancelling = ref(false)
    const retrying = ref(false)

    return {
      model,
      selected,
      cancelling,
      retrying,
      titleCase,
      user: computed(() => props.action.user),
      thumbnail: computed(() => model.value?.thumbnail),
      icon: computed(() => props.action.icon),
      subtitle: computed(() => {
        return model.value?.description ?? props.action.label
      }),
      loading: computed(() => {
        return props.action.ulid === store.actions.loading && selected.value
      }),
      paginating: computed(() => {
        return props.action.ulid === store.actions.pending
      }),
      progress: computed(() => {
        if (!props.action.progress?.total) {
          return undefined
        }

        return ((props.action.progress.current || 0) / props.action.progress.total) * 100
      }),
      expandable: can('system'),

      onToggle: () => {
        store.toggleAction(props.action)
      },

      onPaginate(page) {
        this.$store.dispatch('loadAction', { action: this.action, page })
      },

      onCancel: async () => {
        if (!(await Munio.confirm())) {
          return
        }

        try {
          cancelling.value = true
          Munio.api.integration(props.action.integration.id).action(props.action.ulid).cancel()
        } catch (err) {
          console.error(err)
        } finally {
          cancelling.value = false
        }
      },

      onRetry: async () => {
        try {
          retrying.value = true
          Munio.api.integration(props.action.integration.id).action(props.action.ulid).retry()
        } catch (err) {
          console.error(err)
        } finally {
          retrying.value = false
        }
      },

      getStateIcon: (state) => stateIcon(state),

      getStateColor: (state) => stateColor(state),

      getStateLabelClasses: (state) => stateLabelClasses(state),
    }
  },
}
</script>
