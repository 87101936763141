Promise.prototype.catchAll = function () {
  return this.catch(() => {})
}

/**
 * Immutable helpers
 */

Array.prototype.immutablePush = function (entry) {
  return [...this, entry]
}

Array.prototype.immutablePop = function () {
  return this.slice(0, -1)
}

Array.prototype.immutableShift = function () {
  return this.slice(1)
}

Array.prototype.immutableUnshift = function (entry) {
  return [entry, ...this]
}

Array.prototype.immutableSort = function (callback) {
  return [...this].sort(callback)
}

Array.prototype.immutableReverse = function () {
  return [...this].reverse()
}

Array.prototype.immutableSplice = function (start, deleteCount, ...items) {
  return [...this.slice(0, start), ...items, ...this.slice(start + deleteCount)]
}

Array.prototype.immutableDelete = function (index) {
  return this.slice(0, index).concat(this.slice(index + 1))
}

Array.prototype.immutableCopy = function () {
  return [...this]
}
