<template>
  <mdl-details class="product mx-12 my-8 pb-8" id="shop-product" :summary="trans('Details')">
    <div class="product-details">
      <dl class="m-0">
        <div v-if="product.targetAudience.default">
          <dt class="mdl-subheader mb-0">{{ trans('Target audience') }}</dt>
          <dd v-html="product.targetAudience.default" />
        </div>

        <template v-if="product.course">
          <div>
            <dt class="mdl-subheader mb-0">{{ trans('Language') }}</dt>
            <dd>{{ languages }}</dd>
          </div>
          <div v-if="product.course.duration.minutes">
            <dt class="mdl-subheader mb-0">{{ trans('Duration') }}</dt>
            <dd v-html="product.course.duration.human" />
          </div>
        </template>

        <div v-if="product.exam.default">
          <dt class="mdl-subheader mb-0">{{ trans('Exam') }}</dt>
          <dd v-html="product.exam.default" />
        </div>

        <div v-if="product.criteria.default">
          <dt class="mdl-subheader mb-0">{{ trans('Completion criteria') }}</dt>
          <dd v-html="product.criteria.default" />
        </div>

        <div v-if="product.certificate.default">
          <dt class="mdl-subheader mb-0">{{ trans('Certificate') }}</dt>
          <dd v-html="product.certificate.default" />
        </div>

        <div v-if="product.course">
          <dt class="mdl-subheader mb-0">{{ trans('Producer') }}</dt>
          <dd>{{ product.course.company.name }}</dd>
        </div>

        <div>
          <dt class="mdl-subheader mb-0">{{ trans('Provider') }}</dt>
          <dd>{{ product.provider.name }}</dd>
        </div>

        <div v-if="product.categories.length">
          <dt class="mdl-subheader mb-0">{{ trans('Categories') }}</dt>
          <dd>
            <ul class="list list-unstyled">
              <li v-for="category in product.categories" :key="category.id">
                <a :href="category.url">{{ category.name.default }}</a>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </mdl-details>
</template>

<script>
export default {
  props: {
    product: { type: Object, required: true },
  },

  computed: {
    languages() {
      return Object.keys(this.product.languages)
        .reduce((languages, key) => {
          return languages.push(this.product.languages[key].name), languages
        }, [])
        .join(', ')
    },
  },
}
</script>
